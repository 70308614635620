<template>
  <teleport to="body">
    <base-modal
      size="md"
      :show="show"
      has-close-btn
      :heading="headingText"
      :warning="warning"
      :danger="danger"
      :cancel-button-text="cancelButtonText"
      :auto-focus-modal="false"
      @close:modal="() => $emit('close:modal')"
    >
      <template
        v-if="reason || confirmValue || confirmText || $slots.body"
        #default
      >
        <div class="tw-flex tw-flex-col tw-justify-center tw-space-y-6">
          <p v-if="confirmText" class="tw-whitespace-pre-line">
            {{ confirmText }}
          </p>
          <slot name="body" />
          <div v-if="reason || confirmValue" class="tw-space-y-2">
            <base-input
              v-if="reason"
              test-id="delete-reason"
              focus
              :label="reasonLabel"
              :model-value="reasonInput"
              :warning="warning"
              :danger="danger"
              :errors="validationErrors.reason"
              :required="formValidation.reason.required"
              :min="formValidation.reason.min"
              :max="formValidation.reason.max"
              :show-count="formValidation.reason.min > 0"
              @input="(input) => (reasonInput = input)"
              @enter="confirmDelete"
            />

            <template v-if="confirmValue">
              <p class="tw-break-words tw-space-x-1">
                Please type
                <span class="tw-font-bold">{{ confirmValue }}</span> to confirm.
              </p>
              <base-input
                :focus="focus"
                :model-value="confirmValueInput"
                :placeholder="confirmValue"
                :warning="warning"
                :danger="danger"
                :errors="validationErrors.confirmValue"
                :max="0"
                @input="(input) => (confirmValueInput = input)"
                @enter="confirmDelete"
              />
            </template>
          </div>
        </div>
      </template>

      <template #footer>
        <base-button
          test-id="confirm-delete"
          :focus="!reason && !confirmValue"
          :success="success"
          :info="info"
          :warning="warning"
          :danger="danger"
          :status="status"
          full-width-at="sm"
          :disabled="
            (confirmValue && confirmValue !== confirmValueInput) ||
            (reason &&
              (reasonInput.length < formValidation.reason.min ||
                reasonInput.length > formValidation.reason.max))
          "
          icon="exclamation-triangle"
          @click="confirmDelete"
          >{{ buttonText }}
        </base-button>
      </template>
    </base-modal>
  </teleport>
</template>

<script>
import { statusConfig as formValidation } from '@config/validation.js'
import { required, match } from '@helpers/validationRules.js'
import { useValidate } from '@composables'
import { watch, ref, toRefs } from 'vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: true,
    },
    headingText: {
      type: String,
      default: '',
      required: true,
    },
    confirmText: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Delete',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    reason: {
      type: Boolean,
      default: false,
    },
    reasonLabel: {
      type: String,
      default: 'Reason for deleting',
    },
    status: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
  },
  emits: ['close:modal', 'delete'],
  setup(props, { emit }) {
    const { confirmValue, reason, show } = toRefs(props)
    const reasonInput = ref('')
    const confirmValueInput = ref('')

    const validationErrors = ref({
      reason: [],
    })

    watch(show, (value) => {
      if (value) {
        confirmValueInput.value = ''
        reasonInput.value = ''
        validationErrors.value.reason?.splice(0)
      }
    })

    function confirmDelete() {
      const validator = useValidate({
        confirmValue: [required, match(confirmValue.value)],
        reason: formValidation.validation.reason,
      })
      validationErrors.value = validator.errors

      if (!confirmValue.value && !reason.value) {
        emit('delete')
      } else {
        if (confirmValue.value) {
          validator.validate('confirmValue', confirmValueInput.value)
        }
        if (reason.value) {
          validator.validate('reason', reasonInput.value)
        }

        if (!validator.hasErrors()) {
          emit('delete', reasonInput.value)
        }
      }
    }

    return {
      confirmValueInput,
      validationErrors,
      formValidation,
      confirmDelete,
      reasonInput,
    }
  },
}
</script>
