<template>
  <inline-message
    v-if="
      isLoggedIn && (isMissingEmail || isUnverified || isMonetizationBlocked)
    "
    :has-close="false"
    :has-icon="false"
    class="tw-mb-6"
  >
    <div class="tw-space-y-2 tw-mb-2">
      <panel-header text="Action required" small />
      <div class="tw-text-p-md">
        <p v-if="isMissingEmail">
          Please enter your email to complete registration and activate your
          account:
        </p>
        <p v-else-if="isUnverified">
          Please verify your email
          <span class="tw-text-info">{{ unverifiedEmail }}</span>
          by entering the security code sent to it:
        </p>
        <p v-else-if="isMonetizationBlocked">
          Your email has been blocked as a result of multiple bounced messages,
          which could be due to an issue with your email server or an incorrect
          address. Please enter an alternative address in the field below.
        </p>
      </div>
    </div>

    <div
      v-if="isMissingEmail || isMonetizationBlocked"
      class="tw-flex tw-flex-col xs:tw-flex-row tw-items-start xs:tw-space-x-2 tw-space-y-2 xs:tw-space-y-0"
    >
      <base-input
        class="tw-flex-1"
        :model-value="email"
        :required="formValidation.email.required"
        :max="formValidation.email.max"
        :errors="validationErrors.email"
        placeholder="Email address"
        type="email"
        @input="(input) => updateField('email', input)"
        @enter="submitEmail"
      />
      <div class="tw-w-full xs:tw-w-auto">
        <base-button
          info
          :status="infoStatus"
          full-width-at="xs"
          @click="submitEmail"
        >
          Submit
        </base-button>
      </div>
    </div>

    <div
      v-else-if="isUnverified"
      class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-justify-between lg:tw-space-x-2 tw-space-y-4 lg:tw-space-y-0"
    >
      <div class="tw-max-w-[22.5rem]">
        <base-input
          code
          class="tw-flex-1"
          :max="formValidation.code.size"
          :model-value="code"
          :errors="apiErrors ? [apiErrors] : validationErrors.code"
          @input="(input) => updateField('code', input)"
          @enter="submitCode"
        />
      </div>
      <div
        class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-end tw-space-y-2 sm:tw-space-y-0 sm:tw-space-x-2 tw-w-full lg:tw-w-auto"
      >
        <base-button
          info
          :status="confirmStatus"
          :disabled="code.length !== formValidation.code.size"
          full-width-at="sm"
          :errors="validationErrors"
          @click="submitCode"
        >
          Submit
        </base-button>
        <base-button
          info
          :status="refreshStatus"
          full-width-at="sm"
          @click="resendCode"
        >
          Resend code
        </base-button>
      </div>
    </div>
  </inline-message>
</template>

<script>
import { useValidate, useStatus, useAsync, addToast } from '@composables'
import { loginConfig as formValidation } from '@config/validation.js'
import InlineMessage from '@components/Message/InlineMessage.vue'
import { MODAL_MERGE_ACCOUNTS } from '@config/globalModals.js'
import { STATUS_NOT_ACCEPTED } from '@config/options.js'
import { setGlobalModal } from '@composables/useModal'
import PanelHeader from '@components/PanelHeader.vue'
import { BUTTON_ANIMATION_DURATION } from '@config'
import { getUserInfoForm } from '@types/user.js'
import { isObjEmpty } from '@helpers/utils.js'
import { ref, computed, provide } from 'vue'
import { authStore } from '@stores'
import {
  refreshSecurityCodeRequest,
  updateUserInfoRequest,
  confirmEmailRequest,
} from '@services'

export default {
  components: {
    InlineMessage,
    PanelHeader,
  },
  setup() {
    const email = ref('')
    const code = ref('')
    const mergeCode = ref(null)
    const mergeMessage = ref(null)
    const { user, setUserInfo, isLoggedIn, pendingEmail, setPendingEmail } =
      authStore()

    const { errors: validationErrors, validate } = useValidate(
      formValidation.validation
    )

    // Set user info
    const {
      data: infoData,
      error: infoError,
      loading: infoLoading,
      run: runInfo,
    } = useAsync(updateUserInfoRequest, 'Failed to update email')
    provide('saving', infoLoading)

    const {
      updateStatus: updateInfoStatus,
      status: infoStatus,
      statusType,
    } = useStatus({
      loading: infoLoading,
      error: infoError,
    })

    // Confirm security code
    const {
      setError: setConfirmError,
      loading: confirmLoading,
      error: confirmError,
      run: runConfirm,
      data: codeData,
    } = useAsync(
      () => confirmEmailRequest(code.value),
      'Failed to verify email'
    )

    const { status: confirmStatus, updateStatus: updateConfirmStatus } =
      useStatus({
        loading: confirmLoading,
        error: confirmError,
      })

    // Refresh security code
    const {
      setError: setRefreshError,
      loading: refreshLoading,
      error: refreshError,
      run: runRefresh,
    } = useAsync(refreshSecurityCodeRequest)

    const { status: refreshStatus, updateStatus: updateRefreshStatus } =
      useStatus({
        loading: refreshLoading,
        error: refreshError,
      })

    const apiErrors = computed(() => confirmError.value || refreshError.value)

    const isMissingEmail = computed(
      () => !user.value?.info?.email && !pendingEmail.value
    )

    const isUnverified = computed(
      () => user.value.info.status === STATUS_NOT_ACCEPTED
    )

    const isMonetizationBlocked = computed(
      () => !!user.value.info.monetization_email_blocked
    )

    const unverifiedEmail = computed(
      () => pendingEmail.value || user.value.info.email
    )

    function updateField(field, input) {
      setConfirmError(null)
      setRefreshError(null)
      if (field === 'email') {
        if (!isObjEmpty(validationErrors.email)) {
          validate(field, input)
        }
        email.value = input
      }
      if (field === 'code') {
        if (
          input.length === 0 ||
          input.length > 5 ||
          !isObjEmpty(validationErrors.code)
        ) {
          validate(field, input)
        }
        code.value = input
        if (input.length === 5) {
          setTimeout(submitCode, 500)
        }
      }
    }

    async function submitEmail() {
      if (!validate('email', email.value) || infoLoading.value) return

      const info = { ...getUserInfoForm(user.value.info), email: email.value }
      await runInfo(info)
      if (!infoError.value) {
        updateInfoStatus(statusType.SUCCESS)
        setPendingEmail(email.value)
        email.value = ''
        addToast({
          title: 'Email Update Success',
          isSuccess: true,
          text: 'Your email has been successfully updated.',
        })
        setTimeout(() => setUserInfo(infoData.value), BUTTON_ANIMATION_DURATION)
      }
    }

    async function submitCode() {
      if (!validate('code', code.value) || confirmLoading.value) return

      setConfirmError(null)
      setRefreshError(null)
      await runConfirm()
      if (!confirmError.value) {
        if (codeData.value) {
          updateConfirmStatus(statusType.SUCCESS)
          if (codeData.value.merge_code) {
            setGlobalModal({
              modal: MODAL_MERGE_ACCOUNTS,
              data: {
                ...codeData.value,
                onCancel: cancelMerge,
                onSuccess: mergeSuccess,
              },
            })
          } else {
            addToast({
              title: 'Email Verification Success',
              isSuccess: true,
              text: 'Your email has been successfully verified.',
            })
            setUserInfo(codeData.value.info)
          }
          code.value = ''
        }
      }
    }

    async function resendCode() {
      setConfirmError(null)
      setRefreshError(null)
      await runRefresh()
      if (!refreshError.value) {
        updateRefreshStatus(statusType.SUCCESS)
      }
    }

    async function cancelMerge() {
      await runInfo(getUserInfoForm(user.value.info))
      setUserInfo(infoData.value)
      mergeCode.value = null
      code.value = ''
      setPendingEmail('')
      setConfirmError(null)
    }

    function mergeSuccess() {
      mergeCode.value = null
      code.value = ''
      setPendingEmail('')
      setConfirmError(null)
    }

    return {
      isMonetizationBlocked,
      validationErrors,
      unverifiedEmail,
      isMissingEmail,
      formValidation,
      refreshStatus,
      confirmStatus,
      mergeSuccess,
      mergeMessage,
      isUnverified,
      updateField,
      submitEmail,
      cancelMerge,
      isLoggedIn,
      infoStatus,
      submitCode,
      resendCode,
      apiErrors,
      mergeCode,
      email,
      user,
      code,
    }
  },
}
</script>
