<template>
  <div>
    <div
      class="tw-flex md:tw-hidden tw-px-5 tw-py-2 tw-border-b-2 tw-border-theme-3"
    >
      <notification-message-buttons :sidebar="false" />
    </div>
    <div
      class="tw-p-5 md:tw-pt-8 tw-flex tw-flex-col tw-items-start tw-overflow-x-hidden"
    >
      <base-link
        :link-primary="false"
        class="tw-w-full tw-group"
        :to="{
          name: USER_MOD_BROWSE_ROUTE,
          params: { user: user.info.name_id },
        }"
      >
        <div
          class="tw-flex tw-items-center tw-space-x-3 tw-h-14 tw-p-y-2 tw-px-4 tw-mb-5 tw-border-2 tw-border-primary tw-global--border-radius group-hover:tw-bg-dark-1 group-focus:tw-bg-dark-1"
        >
          <avatar-icon
            inherit-size
            class="tw-w-10 tw-h-10 tw-rounded-full tw-overflow-hidden"
            :user="user"
          />
          <div class="tw-text-left">
            <tooltip-hover
              position="bottom"
              :text="usernameOverflow ? user.info.display_name : ''"
            >
              <p
                ref="usernameEl"
                class="tw-font-bold tw-text-md tw-truncate tw-max-w-40"
              >
                {{ user.info.display_name }}
              </p>
            </tooltip-hover>

            <p class="tw-font-normal tw-opacity-70">View profile</p>
          </div>
        </div>
      </base-link>

      <nav-links :links="profileNavigation" />

      <div class="tw-w-full tw-h-px tw-bg-dark-3 tw-mb-6" />
      <base-button text-link-hover @click="logout">Log out</base-button>
    </div>
  </div>
</template>

<script>
import NotificationMessageButtons from '@components/Navbar/NotificationMessageButtons.vue'
import TooltipHover from '@components/Message/TooltipHover.vue'
import { USER_MOD_BROWSE_ROUTE } from '@config/routeNames.js'
import AvatarIcon from '@components/Users/AvatarIcon.vue'
import { profileNavigation } from '@config/navigation.js'
import NavLinks from '@components/Navbar/NavLinks.vue'
import { useAsync, useRoute } from '@composables'
import { logoutRequest } from '@services'
import { ref, computed } from 'vue'
import { authStore } from '@stores'

export default {
  components: {
    NotificationMessageButtons,
    TooltipHover,
    AvatarIcon,
    NavLinks,
  },
  setup() {
    const { run, error } = useAsync(() => logoutRequest())
    const { user, isLoggedIn } = authStore()
    const { redirectOnLogout } = useRoute()

    const usernameEl = ref(undefined)

    const usernameOverflow = computed(() => {
      return usernameEl.value?.scrollWidth > usernameEl.value?.clientWidth
    })

    async function logout() {
      await run()
      if (!error.value) {
        redirectOnLogout()
      }
    }

    return {
      USER_MOD_BROWSE_ROUTE,
      profileNavigation,
      usernameOverflow,
      isLoggedIn,
      usernameEl,
      logout,
      user,
    }
  },
}
</script>
