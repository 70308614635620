<template>
  <th
    class="tw-h-10 tw-px-3 tw-space-x-1.5 tw-whitespace-nowrap tw-font-normal tw-border-dark-1"
    :class="[
      {
        'md:tw-sticky tw-shadow-cell-right md:tw-shadow-dark-1 tw-left-0 tw-z-1':
          checkbox && hasLeftScroll,
        'tw-sticky tw-shadow-cell-left tw-shadow-dark-1 tw-right-0':
          actionCell && hasRightScroll,
      },
      sortOptions && sortable
        ? 'tw-cursor-pointer tw-group'
        : 'tw-cursor-default',
    ]"
    @click="updateSort"
  >
    <div class="tw-inline-flex tw-space-x-1.5">
      <div class="tw-inline-flex tw-items-center">
        <span
          class="tw-opacity-70 tw-button-transition tw-text-theme tw-font-medium"
          :class="{
            'group-hover:tw-opacity-100': sortable,
          }"
        >
          {{ label }}
        </span>
        <base-checkbox
          v-if="checkbox"
          :background="false"
          :model-value="allChecked"
          @input="$emit('toggle:checked')"
        />
        <tooltip-hover
          v-if="tooltip"
          tip-hover
          position="top left"
          background="primary"
          class="tw--mr-4"
          :text="tooltip"
        />
      </div>
      <div
        v-if="sortable"
        class="tw-flex tw-flex-col tw-h-4 tw-relative tw-top-px"
      >
        <font-awesome-icon
          icon="angle-up"
          size="xl"
          class="tw-left-0 tw-transition-all tw--mt-px"
          :class="[
            {
              'tw-rotate-180':
                (!showCaretUp && isSorted) || (!isSorted && defaultCaretDown),
            },
            isSorted ? 'tw-text-primary' : 'tw-opacity-30 tw-text-theme',
          ]"
        />
      </div>
    </div>
  </th>
</template>

<script>
import TooltipHover from '@components/Message/TooltipHover.vue'
import { computed, toRefs } from 'vue'
export default {
  components: {
    TooltipHover,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    sort: {
      type: Object,
      default: null,
    },
    sortOptions: {
      type: Object,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: null,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    allChecked: {
      type: Boolean,
      default: false,
    },
    hasRightScroll: {
      type: Boolean,
      default: false,
    },
    hasLeftScroll: {
      type: Boolean,
      default: false,
    },
    actionCell: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:sort', 'toggle:checked'],
  setup(props, { emit }) {
    const { sort, sortOptions, sortable } = toRefs(props)

    const isSorted = computed(
      () => sort.value?.sortBy === sortOptions.value?.key
    )

    function updateSort() {
      if (sortOptions.value && sortable.value) {
        emit('update:sort', sortOptions.value)
      }
    }

    const defaultCaretDown = computed(
      () => sortOptions.value?.direction === 'desc'
    )

    const showCaretDown = computed(
      () =>
        (sort.value?.order === 'desc' && !sort.value?.reverse) ||
        (sort.value?.order === 'asc' && sort.value?.reverse)
    )

    const showCaretUp = computed(
      () =>
        (sort.value?.order === 'asc' && !sort.value?.reverse) ||
        (sort.value?.order === 'desc' && sort.value?.reverse)
    )

    return {
      defaultCaretDown,
      showCaretDown,
      showCaretUp,
      updateSort,
      isSorted,
    }
  },
}
</script>
