import { convertToDecimal, formatAmount } from '@helpers/monetizationUtils.js'
import { CURRENCY_USD, CURRENCY_MIO } from '@config/monetization.js'
import { GAME_PLATFORM_LABELS } from '@config/options.js'
import { useMetricsDates } from '@composables'
import { THUMB_BASE_URL } from '@config'
import { DateTime } from 'luxon'
import { md5 } from 'js-md5'
import Color from 'color'
import {
  METRICS_GAME_PLATFORM_LABELS,
  METRICS_HEALTH_LABELS,
  BIG_NUMBER_PREFIXES,
  METRICS_DATA_TYPES,
  PLATFORM_ORDER,
} from '@config/chartOptions.js'
import { unref } from 'vue'
import {
  getDaysBetweenTwoDates,
  isNullOrUndefined,
  humanNumberFormat,
  durationFormatter,
  humanFilesize,
  norm,
} from '@helpers/utils.js'

export function getDailyAverageUsers(users, startDate, endDate) {
  if (!users || !startDate) return 0

  const days = getDaysBetweenTwoDates(startDate, endDate)
  return Math.floor(users / days)
}

export function getFlooredPercentage(val1, val2) {
  return getRoundedPercentage(val1, val2, true)
}

export function getRoundedPercentage(val1, val2, floor = false, decimals = 1) {
  let decimalNum = Math.pow(10, decimals)

  if (!val1 || !val2) {
    return 0
  }

  if (floor) {
    return Math.floor((val1 / val2) * 100 * decimalNum) / decimalNum
  }
  return Math.round((val1 / val2) * 100 * decimalNum) / decimalNum
}

export function getRatio(val1, val2, single = false) {
  let ratio = { firstValue: '0', secondValue: '0' }
  let result1 = val1
  let result2 = val2

  if (isNullOrUndefined(result1) || !result2) {
    return ratio
  }

  for (let i = result2; i > 1; i--) {
    if (!(result1 % i) && !(result2 % i)) {
      result1 = result1 / i
      result2 = result2 / i
    }
  }

  if (single) {
    ratio.firstValue = humanNumberFormat(Math.floor(result1 / result2))
    ratio.secondValue = '1'
  } else {
    ratio.firstValue = humanNumberFormat(result1)
    ratio.secondValue = humanNumberFormat(result2)
  }

  return ratio
}

export function normaliseValue(value, min, max) {
  const normalisedValue = norm(value, min, max, 100)
  if (!isNaN(normalisedValue)) {
    return normalisedValue
  } else {
    return 0
  }
}

export function getUserAverageForMonth(sum, days) {
  if (!sum || !days) return 0
  return Math.floor(sum / days)
}

export function getUserSumForMonth(data, date) {
  let sum = 0
  let count = 0

  const entries = data.filter(
    (x) => new Date(x.label).getMonth() === date.getMonth()
  )

  count = entries.length

  for (let i = 0; i < count; i++) {
    sum += entries[i].value
  }
  return { sum, count }
}

export const getSumFromArray = (array) => {
  if (!array || array.length === 0) return 0

  return array.reduce((x, y) => x + y, 0)
}

export const getMeanFromArray = (array) => {
  if (!array || array.length === 0) return 0

  return getSumFromArray(array.map((x) => x || 0)) / array.length
}

export function getMonthlyUsers(usersList, comparison = false) {
  const startOftwoMonthsAgo = DateTime.now()
    .minus({ months: 2 })
    .startOf('month')
    .startOf('day')

  const startOfOneMonthAgo = DateTime.now()
    .minus({ months: 1 })
    .startOf('month')
    .startOf('day')

  const startOfCurrentMonth = DateTime.now().startOf('month').startOf('day')
  const result = usersList?.filter((x) => {
    const userDate = DateTime.fromISO(x.label, { zone: 'utc' }).startOf('month')
    return comparison
      ? userDate > startOftwoMonthsAgo && userDate < startOfOneMonthAgo
      : userDate > startOfOneMonthAgo && userDate < startOfCurrentMonth
  })
  if (result?.length > 0) {
    if (result.length > 1) {
      let total = 0
      for (let i = 0; i < result.length; i++) {
        total += result[i].value
      }
      return total
    } else {
      return result[0].value
    }
  }
  return 0
}

export function getDailyUsers(userList, comparison = false) {
  const result = userList?.find((x) =>
    comparison
      ? DateTime.fromISO(x.label, { zone: 'utc' })
          .startOf('day')
          .equals(
            DateTime.now().toUTC().minus({ days: 1, months: 1 }).startOf('day')
          )
      : DateTime.fromISO(x.label, { zone: 'utc' })
          .startOf('day')
          .equals(DateTime.now().toUTC().minus({ days: 1 }).startOf('day'))
  )
  if (result) {
    return result.value
  }
  return 0
}

export function getTopMods(
  modList,
  criteria = 'downloads_total',
  topNumber = 5
) {
  if (!modList || modList.length < 1) {
    return []
  }

  const editedList = [...modList]
  const topList = []
  for (let i = 0; i < topNumber; i++) {
    const max = Math.max.apply(
      Math,
      editedList.map((x) => x.stats[criteria])
    )
    const topMod = editedList.find((x) => x.stats[criteria] === max)

    if (topMod) {
      topList.push(topMod)
      editedList.splice(editedList.indexOf(topMod), 1)
    }
  }

  return topList
}

export function formatChartsData(
  data,
  storageType,
  platformTitles,
  withGroupBy,
  groupByText
) {
  const _groupByText = unref(groupByText)
  const { populateMissingDateForSelectedRange } = useMetricsDates()
  const _data = unref(data)
  let result = [
    {
      name: `All ${_groupByText}`,
      data: [],
    },
  ]
  if (!_data) return result

  result[0].data =
    populateMissingDateForSelectedRange(_data.series, storageType) || []

  if (platformTitles || groupByText) {
    result = result.concat(
      _data.group_by?.map((x) => ({
        name: withGroupBy ? x.label : METRICS_GAME_PLATFORM_LABELS[x.label],
        data: populateMissingDateForSelectedRange(x.series, storageType) || [],
      }))
    )

    if (platformTitles) {
      // Fill in missing platforms in data.
      platformTitles.value.slice(1).forEach((x) => {
        if (!result.find((item) => item.name === x))
          result.push({ name: x, data: [] })
      })

      result.sort(
        (x, y) =>
          PLATFORM_ORDER.indexOf(x.name) - PLATFORM_ORDER.indexOf(y.name)
      )
    }
  }

  return result
}

export function getPeriodTotal(data) {
  const { dateRange } = useMetricsDates()
  return (
    data.value?.series
      .filter(
        (x) =>
          DateTime.fromISO(x.label) <= dateRange.value.endDate &&
          DateTime.fromISO(x.label) >= dateRange.value.startDate
      )
      .map((x) => x.value)
      .reduce((a, b) => a + b, 0) || 0
  )
}

export function getPeriodComparison(currentTotal, comparisonTotal) {
  return comparisonTotal > 0
    ? ((currentTotal - comparisonTotal) / comparisonTotal) * 100
    : 0
}

export function getWeekCategories(currentDate, endDate) {
  let result = []
  let dateSets = []

  while (currentDate <= endDate) {
    if (currentDate.plus({ weeks: 1 }) > endDate) {
      const daysToAdd = Math.floor(endDate.diff(currentDate, 'days').days) || 1

      result.push(
        `${currentDate.weekdayShort} ${currentDate.day} ${
          currentDate.monthShort
        } - ${currentDate.plus({ days: daysToAdd }).weekdayShort} ${
          currentDate.plus({ days: daysToAdd }).day
        } ${currentDate.plus({ days: daysToAdd }).monthShort}`
      )

      dateSets.push([currentDate, currentDate.plus({ days: daysToAdd + 1 })])

      currentDate = currentDate.plus({
        days: daysToAdd + 1,
      })
    } else {
      result.push(
        `${currentDate.weekdayShort} ${currentDate.day} ${
          currentDate.monthShort
        } - ${currentDate.plus({ weeks: 1 }).weekdayShort} ${
          currentDate.plus({ weeks: 1 }).day
        } ${currentDate.plus({ weeks: 1 }).monthShort}`
      )

      dateSets.push([currentDate, currentDate.plus({ weeks: 1 })])

      currentDate = currentDate.plus({ weeks: 1 }).plus({ days: 1 })
    }
  }
  return { categories: result, dateSets }
}

export function getBreakdownText(recordedDate) {
  const _recordedDate = recordedDate ? recordedDate : '2023-02-08'
  const longDate = DateTime.fromISO(_recordedDate).toLocaleString({
    dateStyle: 'full',
  })
  return `Data breakdown not recorded prior to\n${longDate}`
}

export function getPlatformColour(platformName) {
  switch (platformName) {
    case GAME_PLATFORM_LABELS.xboxseriesx:
      return '#0e7a0d'
    case GAME_PLATFORM_LABELS.xboxone:
      return '#145913'
    case GAME_PLATFORM_LABELS.ps4:
      return '#0070D1'
    case GAME_PLATFORM_LABELS.ps5:
      return '#00439C'
    case GAME_PLATFORM_LABELS.switch:
      return '#e60012'
    case GAME_PLATFORM_LABELS.windows:
      return '#989095'
    case GAME_PLATFORM_LABELS.linux:
      return '#494447'
    case GAME_PLATFORM_LABELS.ios:
      return '#b166cc'
    case GAME_PLATFORM_LABELS.mac:
      return '#6A6367'
    case GAME_PLATFORM_LABELS.oculus:
      return '#0080fa'
    case GAME_PLATFORM_LABELS.android:
      return '#d69eea'
    case METRICS_GAME_PLATFORM_LABELS.unknown:
      return '#00dddd'
    default:
      return '#67b7dc'
  }
}

export function getHealthColour(name) {
  switch (name) {
    case METRICS_HEALTH_LABELS.DMCA:
      return Color('#fd7f6f').desaturate(0.4).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.FALSE_INFO:
      return Color('#7eb0d5').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.ILLEGAL_CONTENT:
      return Color('#b2e061').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.NOT_WORKING:
      return Color('#bd7ebe').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.OTHER:
      return Color('#ffb55a').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.RUDE_CONTENT:
      return Color('#ffee65').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.STOLEN_CONTENT:
      return Color('#beb9db').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.AUTO_VIRUS:
      return Color('#fdcce5').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.GENERIC:
      return Color('#8bd3c7').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.DELETED_NOT_ACCEPTED:
      return Color('#fd7f6f').desaturate(0.4).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.DELETED_ACCEPTED:
      return Color('#7eb0d5').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.BANNED_NOT_ACCEPTED:
      return Color('#b2e061').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.BANNED_ACCEPTED:
      return Color('#bd7ebe').desaturate(0.1).darken(0.4).hex()
    case METRICS_HEALTH_LABELS.BANNED_DELETED:
      return Color('#ffb55a').desaturate(0.1).darken(0.4).hex()
    default:
      return '#67b7dc'
  }
}

export function formatDataType(type, val, defaultNumberFormatter) {
  switch (type) {
    case METRICS_DATA_TYPES.FILESIZE:
      return humanFilesize(val)
    case METRICS_DATA_TYPES.DOLLARS:
      return formatAmount(val, CURRENCY_USD)
    case METRICS_DATA_TYPES.TOKENS:
      return formatAmount(val, CURRENCY_MIO)
    case METRICS_DATA_TYPES.TIME:
      return durationFormatter(val)
    default:
      return defaultNumberFormatter
        ? defaultNumberFormatter(val)
        : humanNumberFormat(val)
  }
}

export function setAxisFormatType(
  type,
  am5DurationFormatter,
  axisFormat,
  root,
  negative
) {
  switch (type) {
    case METRICS_DATA_TYPES.FILESIZE:
      axisFormat.numberFormat = '#.b'
      break
    case METRICS_DATA_TYPES.DOLLARS:
      axisFormat.numberFormat = '$#'
      break
    case METRICS_DATA_TYPES.TIME:
      axisFormat.numberFormatter = am5DurationFormatter.new(root, {
        baseUnit: 'minute',
        durationFormat: 'hh:mm',
      })
      break
    default:
      axisFormat.numberFormat = '#a'
      root.numberFormatter.setAll({
        smallNumberPrefixes: [],
        bigNumberPrefixes: BIG_NUMBER_PREFIXES,
      })
      axisFormat.smallNumberThreshold = 0.001
      axisFormat.maxZoomFactor = Infinity
  }

  if (!negative) axisFormat.min = 0
}

export function formatChartDataType(type) {
  switch (type) {
    case METRICS_DATA_TYPES.FILESIZE:
      return '#.b'
    case METRICS_DATA_TYPES.DOLLARS:
      return '$#.##'
    default:
      return '#,###.'
  }
}

export function convertIfDollarsType(type, value) {
  return type === METRICS_DATA_TYPES.DOLLARS
    ? convertToDecimal(value, CURRENCY_USD)
    : value
}

export function statusClass(value, item, first, second, flipSign = false) {
  const signMul = flipSign ? -1 : 1
  return value * signMul >= first * signMul
    ? `tw-${item}-success`
    : value * signMul >= second * signMul
      ? `tw-${item}-warning`
      : `tw-${item}-danger`
}

export function statusClassUptime(percent, item) {
  return statusClass(percent, item, 100, 95)
}

export function statusClassResponseTime(responseTime) {
  return statusClass(responseTime, 'text', 500, 1000, true)
}

export function buildThumbImage(
  resourceName,
  resourceId,
  image,
  cropOption = 'crop_320x180'
) {
  if (!image) return null

  const thumbResource = `${THUMB_BASE_URL}/${resourceName}`

  return `${thumbResource}/${md5(String(resourceId)).slice(0, 4)}/${resourceId}/${cropOption}/${image}`
}
